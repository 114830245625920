





import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Cmscta } from '@Core/@types/skyway'

@Component({
  inheritAttrs: false,
  components: {
    Cta: () => import('@UI/components/cta/Cta.vue'),
    RHWorksCta: () => import('@UI/components/cta/RHWorksCta.vue'),
  },
})
export default class CtaBlock extends Vue {
  @Prop({ type: Object, required: true }) data!: Cmscta & { id: string }

  get componentName(): string {
    return this.data.extra.type === 'RoundhouseWorksCTA' ? 'RHWorksCta' : 'Cta'
  }

  get componentWrapperClass(): string | false {
    return (
      (this.data.extra.type === 'primary' ||
        this.data.extra.type === 'secondary') &&
      'container layout-grid'
    )
  }

  get componentClass(): string | false {
    return (
      (this.data.extra.type === 'primary' ||
        this.data.extra.type === 'secondary') &&
      'sm:grid-col-12 md:grid-col-10 md:grid-col-start-2 lg:grid-col-8 lg:grid-col-start-3'
    )
  }
}
